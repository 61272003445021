import { get_token } from '@/utils/cookie-storage';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Home() {
  const router = useRouter();
  useEffect(() => {
    const token = get_token();
    if (token) {
      router.push('/account/dashboard');
    } else {
      router.push('/auth');
    }
  }, [router]);
  return null;
}
